<template>
  <q-card class="l-wrapper" flat square bordered>
    <q-scroll-area class="l-grow">
      <div class="q-gutter-y-md q-pa-md ">
        <div>
          <q-btn
            flat
            dense
            label="back"
            icon="arrow_back"
            color="primary"
            @click="router.go(-1)"
          ></q-btn>
        </div>
        <div class="">
          <label class="text-bold"> Nama Sales :</label>
          <q-input
            outlined
            v-model="selDevelopment.username"
            readonly
          ></q-input>
        </div>
        <div class="">
          <label class="text-bold">
            <span class="text-red">*</span> Tipe Request :</label
          >
          <q-select
            outlined
            v-model="selDevelopment.type"
            :options="opt.type"
          ></q-select>
        </div>
        <div class="">
          <label class="text-bold">
            <span class="text-red">*</span> Nama Item :</label
          >
          <q-input outlined v-model="selDevelopment.name"></q-input>
        </div>
        <div class="">
          <label class="text-bold">
            <span class="text-red">*</span> Customer :</label
          >
          <q-select
            ref="customerSelect"
            outlined
            v-model="selDevelopment.id_customer"
            :options="opt.filCustomer"
            @filter="filterCustomer"
            map-options
            emit-value
            use-input
            :key="key"
          ></q-select>
        </div>

        <div class="" v-if="selDevelopment.type != 'COMPLAINT'">
          <label class="text-bold">
            <span class="text-red">*</span> Target Price (Rp) :</label
          >
          <q-field
            outlined
            v-model="selDevelopment.target_price"
            class="bg-white"
          >
            <template
              v-slot:control="{ id, floatingLabel, modelValue, emitValue }"
            >
              <money3comp
                :id="id"
                class="q-field__input"
                :model-value="modelValue"
                @update:model-value="emitValue"
                v-bind="configMoney"
                v-show="floatingLabel"
              ></money3comp>
            </template>
          </q-field>
        </div>
        <div class="" v-if="selDevelopment.type != 'COMPLAINT'">
          <label class="text-bold">
            <span class="text-red">*</span> Potential Qty (Kg) :</label
          >
          <q-field
            outlined
            v-model="selDevelopment.potential_qty"
            class="bg-white"
          >
            <template
              v-slot:control="{ id, floatingLabel, modelValue, emitValue }"
            >
              <money3comp
                :id="id"
                class="q-field__input"
                :model-value="modelValue"
                @update:model-value="emitValue"
                v-bind="configQty"
                v-show="floatingLabel"
              ></money3comp>
            </template>
          </q-field>
        </div>
        <div class="">
          <label class="text-bold">
            <span class="text-red">*</span> Deadline :</label
          >

          <q-input
            outlined
            v-model="selDevelopment.deadline"
            readonly
            mask="date"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="selDevelopment.deadline"
                    :options="optionsDate"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="column q-gutter-xs">
          <label class="text-bold"> Request Note :</label>
          <q-input
            outlined
            v-model="selDevelopment.request_note"
            type="textarea"
          ></q-input>
        </div>
        <div class="text-red text-italic">* Wajib Diisi</div>
      </div>
      <q-card-actions class="q-pa-none" align="stretch">
        <q-btn
          unelevated
          v-if="route.params.id == '-1'"
          type="submit"
          color="primary"
          class="full-width no-border-radius"
          label="DAFTARKAN DEvelopment"
          @click.prevent="saveDevelopment"
        ></q-btn>
        <q-btn
          unelevated
          v-else
          type="submit"
          color="primary"
          class="full-width no-border-radius"
          label="SIMPAN PERUBAHAN"
          @click.prevent="updateDevelopment"
        ></q-btn>
      </q-card-actions>
    </q-scroll-area>
  </q-card>
</template>

<script>
import { computed, reactive, ref, inject } from "vue";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { useQuasar } from "quasar";
import { Money3Component } from "v-money3";

export default {
  components: { money3comp: Money3Component },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const $q = useQuasar();
    let $http = inject("$http");

    let curUser = ref({});
    let key = ref(0);

    onMounted(async () => {
      curUser.value = JSON.parse(JSON.stringify(route.meta.user));
      if (route.params.id == -1) {
        selDevelopment.value.id_user = curUser.value.id;
        selDevelopment.value.username = curUser.value.username;
        selDevelopment.value.type = "NEW";
        await getCustomerList();
      } else {
        $q.loading.show({ message: "mohon tunggu..." });
        await getCustomerList();
        await getRequestDetail();
        $q.loading.hide();
      }
    });
    let selDevelopment = ref({});

    let getCustomerList = async () => {
      let resp = await $http({
        method: "get",
        url: `/customer/list-customer/${route.meta.user.id}/1`,
        baseURL: process.env.VUE_APP_BASE_URL_SALES,
      });
      opt.customer = resp.data.map(({ id: value, name: label }) => ({
        label,
        value,
      }));
    };
    let filterCustomer = (val, update, abort) => {
      update(() => {
        const needle = val.toLowerCase();
        opt.filCustomer = opt.customer.filter((v) => {
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    };

    let getRequestDetail = async () => {
      let req_id = route.params.id;
      let resp = await $http.get(`/development/detail/${req_id}`);
      selDevelopment.value = resp.data;
      // selDevelopment.value.id_customer = "19";
      customerSelect.value.showPopup();

      key.value++;
    };

    let opt = reactive({
      customer: [],
      filCustomer: [],
      type: ["NEW", "COMPLAINT", "COST REDUCTION", "REFORMULATION"],
    });

    let saveDevelopment = async () => {
      try {
        if (selDevelopment.value.potential_qty == undefined)
          selDevelopment.value.potential_qty = 0;
        if (selDevelopment.value.target_price == undefined)
          selDevelopment.value.target_price = 0;
        let resp = await $http.post("/development/add", selDevelopment.value);
        let customer = opt.filCustomer.find((val) => {
          return val.value == selDevelopment.value.id_customer;
        }).label;
        // console.log(cus);
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_rnd_supervisor"],
          content: `Development Request Baru Telah Dibuat. Item : ${selDevelopment.value.name} , Customer : ${customer}`,
          router_to: `/app/development/chat/${resp.data.id}`,
        };
        await $http.post(`/notification/broadcastrole`, notifPayload);
        selDevelopment.value = {};
        $q.notify({
          message: "Development Request Berhasil Dibuat",
          color: "positive",
        });
        router.go(-1);
      } catch (error) {
        $q.notify({
          message: "Development Request Gagal Dibuat",
          color: "negative",
        });
        console.log("error on saving development request");
        console.log(error);
      }
    };
    let updateDevelopment = async () => {
      try {
        let dev = JSON.parse(JSON.stringify(selDevelopment.value));
        dev.deadline = moment(dev.deadline).format("YYYY-MM-DD");
        await $http.post(`/development/update-detail/${route.params.id}`, dev);
        router.go(-1);
      } catch (error) {
        console.log("gagal update detail development");
      }
    };

    let optionsDate = (date) => {
      return date > moment().format("YYYY/MM/DD");
    };
    let customerSelect = ref(null);

    let configMoney = ref({
      prefix: "Rp.",
      suffix: "",
      thousands: ",",
      decimal: ".",
      precision: 0,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
    });

    let configQty = ref({
      thousands: ",",
      decimal: ".",
      precision: 0,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
    });

    return {
      selDevelopment,
      customerSelect,
      opt,
      route,
      router,
      saveDevelopment,
      updateDevelopment,
      optionsDate,
      curUser,
      filterCustomer,
      key,
      configQty,
      configMoney,
    };
  },
};
</script>
